import { Component, OnInit } from '@angular/core';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';
import {Nota, Pergunta, PesquisaSatisfacaoService} from '../../services/pesquisa-satisfacao.service';
import {TokenMesaService} from '../../services/token-mesa.service';
import {Observable, of} from 'rxjs';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-pesquisa-satisfacao',
  templateUrl: './pesquisa-satisfacao.component.html',
  styleUrls: ['./pesquisa-satisfacao.component.scss']
})
export class PesquisaSatisfacaoComponent implements OnInit {
  /** Quantas vezes deve-se tentar enviar a pesquisa, quando acontece um erro, antes de desistir do envio. */
  private static readonly MAX_TENTATIVAS_ENVIO = 3;
  /** Variável contendo quantas vezes já se foi tentado enviar uma pesquisa */
  private tentativasEnvio = 0;

  /** Utilidade para uso no template HTML */
  readonly perguntaSugestao = 'Deixe aqui seus comentários';

  /** Atributo de ajuda para o layout */
  protected readonly nota = Nota;
  perguntas: Pergunta[] = [];

  /** Respostas do usuário */
  protected respostaPesquisas: {[id: number]: Nota | null} = {};

  /** Variável contendo o texto da área de digitação, com sugestões, reclamações ou dúvidas */
  textoSugestao = new FormControl('');

  /** Token da mesa. */
  private token = '';

  email = new FormControl('', [Validators.email]);

  constructor(
      private modalController: ModalController,
      private service: PesquisaSatisfacaoService,
      private tokenMesaService: TokenMesaService,
      private loadingController: LoadingController,
      private alertController: AlertController
  ) { }

  ngOnInit() {
    /**
     * Ao inicializar o componente, procura as perguntas registradas na base de dados e inicializa os objetos devidos.
     */
    this.tokenMesaService.getTokenMesa()
      .then(
        token => {
          this.token = token;
          return this.service.getPesquisa(token);
        })
      .then(perguntas => {
      /**
       * Inicializa corretamente as perguntas, e o objeto das respostas. As respostas iniciais sempre são `null`,
       * e se houver ao menos uma pergunta com a resposta nula, a pesquisa não é enviada.
       */
      this.perguntas = perguntas.filter(pergunta => pergunta.idPergunta !== PesquisaSatisfacaoService.idSugestao);
      perguntas.forEach(
        pergunta => {
          if (pergunta.idPergunta === PesquisaSatisfacaoService.idSugestao) {
            this.textoSugestao.setValue(pergunta.respostaObs || '');
          } else {
            this.respostaPesquisas[pergunta.idPergunta] = pergunta.resposta as Nota || null;
          }
        }
      );
    });
  }

  /**
   * Função que inicializa / substitui uma nota dada pelo usuário.
   * @param id identificação da pergunta
   * @param nota nota selecionada pelo usuário
   */
  darNota(id: number, nota: Nota) {
    this.respostaPesquisas[id] = nota;
  }

  /** método de conveniência para fechar o modal que este componente é disposto*/
  async close() {
    await this.modalController.dismiss();
  }

  respostasSaoInvalidas(): Observable<boolean> {
    return of(Object.values(this.respostaPesquisas).includes(null) || this.email.invalid);
  }

  /**
   * Inicializa e trabalha o registro das respostas, trazendo também experiência de usuário na interface.
   */
  async registrarRespostas() {
    const loading = await this.loadingController.create({
      message: 'Carregando',
    });
    await loading.present();
    // Cópia direta das perguntas salvas nesse objeto, para evitar afetar o objeto principal
    const respostas = this.perguntas.slice();

    respostas.forEach(
      pergunta => {
        pergunta.resposta = this.respostaPesquisas[pergunta.idPergunta];
        pergunta.email = this.email.value;
      });

    // Envio das sugestões, reclamações e perguntas somente se houver um texto
    if (this.textoSugestao.value.length > 0) {
      const sugestaoResposta: Pergunta = {
        token: this.token,
        email: this.email.value,
        pergunta: this.perguntaSugestao,
        idPergunta: PesquisaSatisfacaoService.idSugestao,
        respostaObs: this.textoSugestao.value,
      };

      respostas.push(sugestaoResposta);
    }

    this.service.enviarPesquisaSatisfacao(this.token, respostas)
      .then(
        (response: any) => {
          loading.dismiss();
          if (response.sucesso) {
            this.presentAlert(
              'Pesquisa Enviada! 😄',
              'Você será redirecionado para o menu.',
              () => this.close()
            );
          } else {
            throw new Error(response.msg || '');
          }
        })
      .catch(
        () => {
          loading.dismiss();
          this.tentativasEnvio += 1;
          if (this.tentativasEnvio >= PesquisaSatisfacaoComponent.MAX_TENTATIVAS_ENVIO) {
            this.presentAlert(
              'Não deu...',
              'Algo está realmente impedindo de enviar suas respostas. Por hora, vamos cancelar sua pesquisa de satisfação.',
              () => this.close()
            );
          } else {
            this.presentAlert(
              'Ah, não! 😭',
              `Algo deu errado ao enviar suas respostas... Vamos tentar novamente?\n\n${this.tentativasEnvio}/3`,
              () => this.registrarRespostas()
            );
          }
        }
      );
  }

  /**
   * Função de ajuda para apresentar popups de alerta na janela.
   * @param header Título da mensagem
   * @param message Corpo da mensagem
   * @param callback Função a ser chamada ao clicar no botão 'Ok'
   */
  async presentAlert(header: string, message: string, callback: () => void) {
    const alert = await this.alertController.create({
      header,
      message,
      cssClass: 'alert-500',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: callback
        }
      ]
    });

    await alert.present();
  }

}
