import { EmpresaOfflineComponent } from "./pages/empresa-offline/empresa-offline.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BoasVindasComponent } from "./pages/boas-vindas/boas-vindas.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found";
import { CategoriasResolveGuard } from "./resolve/categorias-resolve.guard";
import { ProdutoDetalheComponent } from "./components/produto/produto-detalhe/produto-detalhe.component";

const routes: Routes = [
  { path: "", component: PageNotFoundComponent },
  {
    path: "empresa-offline",
    component: EmpresaOfflineComponent,
  },
  {
    path: "cardapio",
    loadChildren:
      "./pages/cardapio-tablet/cardapio-tablet.module#CardapioTabletPageModule",
    resolve: { categorias: CategoriasResolveGuard },
  },
  { path: "cardapio/:idProduto", component: ProdutoDetalheComponent },
  {
    path: "erro-servidor-nao-encontrado",
    loadChildren: "./components/erro/erro.module#ErroModule",
  },
  { path: "versao", loadChildren: "./pages/versao/versao.module#VersaoModule" },
  { path: "boas-vindas", component: BoasVindasComponent },
  { path: "pagina-nao-encontrada", component: PageNotFoundComponent },
  {
    path: ":servidor",
    loadChildren:
      "./pages/configuracao-servidor/configuracao-servidor.module#ConfiguracaoServidorPageModule",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
