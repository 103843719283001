import * as dayjs from 'dayjs';

import ItemPedido from './item-pedido';

export default interface ConsumirPedidoInterface {
    data: string;
    listaItens: ItemPedido[];
    idEmpresa: number;
    idUsuario: number;
}

export class ConsumirPedidoModel implements ConsumirPedidoInterface {
    data: string;
    listaItens: ItemPedido[];
    idEmpresa: number;
    idUsuario: number;

    constructor(
        listaItens: ItemPedido[],
        idEmpresa: number,
        idUsuario: number) {

        this.data = dayjs().format('DD/MM/YYYY HH:mm:ss');
        this.listaItens = listaItens;
        this.idEmpresa = idEmpresa;
        this.idUsuario = idUsuario;
    }
}
