import { Component, Input } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

import { AlertController } from '@ionic/angular';

import { CarrinhoService } from 'src/app/services/carrinho.service';
import { listAnimation } from 'src/app/animations/animations';
import { BreakpointService } from '../../services/breakpoint.service';
import ItemCart from '../../models/item-cart.model';

@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.scss'],
  animations: [
    listAnimation
  ]
})
export class CarrinhoComponent {

  @Input() public itensCart: ItemCart[];

  constructor(
    private serviceCarrinho: CarrinhoService,
    public breakpointService: BreakpointService,
    private alertController: AlertController) { }

  async onShowAlert(index) {
    await this.presentAlertConfirm(index);
  }

  async presentAlertConfirm(index) {
    const nomeProduto = new TitleCasePipe().transform(this.itensCart[index].nomeProduto);

    const alert = await this.alertController.create({
      header: `Deseja remover '${nomeProduto}' do carrinho? 😲`,
      cssClass: 'alert-500',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Remover',
          handler: () => this.serviceCarrinho.removeAtIndex(index)
        }
      ]
    });

    await alert.present();
  }

}
