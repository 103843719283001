import { Estabelecimento } from './../../models/estabelecimento';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LadTotemService } from 'src/app/services/ladtotem.service';
import { from } from 'rxjs';
import * as  packageJson from 'package.json';

@Component({
  selector: 'app-boas-vindas',
  templateUrl: './boas-vindas.component.html',
  styleUrls: ['./boas-vindas.component.scss'],
})
export class BoasVindasComponent implements OnInit {
  version = packageJson.version;

  /**
   * @var estabelecimento
   */
   estabelecimento?: Estabelecimento;

  /**
   * @var logoEstabelecimento
   */
  logoEstabelecimento?: string;

  /**
   * @var textoEndereco
   */
  textoEndereco: string | null;

  /**
   * @var corPadrao
   */
  corPadrao = 'white';

  /**
   * @var corEstabelecimentoFechado
   */
  corEstabelecimentoFechado: 'rgb(255, 5, 5)';

  /**
   * @var corEstabelecimento
   */
  corEstabelecimentoAberto = '#04c804d4';

  /**
   * @var loading
   */
  loading = false;

  constructor(
    private router: Router,
    private serviceLadTotem: LadTotemService
  ) {}

  /**
   * Quando inicia o componente faz a consulta das Categorias
   * e chama a @function _getDadosEmpresa()
   */
  ngOnInit() {
    from(this.serviceLadTotem.consultaCategoriasTotem()).subscribe();
    this._getDadosEmpresa();
  }

  /**
   * Faz a Consulta dos dados da empresa e usa o id
   * para consultar a imagem/logo da empresa
   */
  private _getDadosEmpresa(): void {
    this.serviceLadTotem.consultaEmpresa().then((res) => {
      this.estabelecimento = res;
      this.logoEstabelecimento = `https://img.ladfood.com.br/empresa/${res.id}.jpg`;

      let descricao = '';

      if (res.complementoEndereco) {
        descricao += ` ${res.endereco},`;
        descricao += ` ${res.complementoEndereco},`;
      } else {
        descricao += ` ${res.endereco},`;
      }
      if (res.bairro) {
        descricao += ` ${res.bairro},`;
      }
      if (res.cidade ) {
        descricao += ` ${res.cidade} - `;
      }
      if (res.uf) {
        descricao += ` ${res.uf},`;
      }
      if (res.cep) {
        descricao += ` ${res.cep}`;
      }

      if (descricao.charAt(descricao.length - 1) === ',') {
        descricao = descricao.slice(0, -1);
      }

      // Informações do Estabelecimento
      this.textoEndereco = descricao;
    });
  }

  /**
   * Chama a função de consultar categorias, e quando termina de consultar
   * navega para a página do cardápio de estabelecimento escolhido.
   */
   async entrarCardapio(): Promise<void> {
    try {

      this.loading = true;
      await this.router.navigate([`/cardapio`]);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
