import { AnimationController } from "@ionic/angular";
import { Animation } from "@ionic/core";

export const myTransitionRouter = (
  baseEl: HTMLElement,
  opts?: any
): Animation => {
  const animation = new AnimationController();

  let fator = 1;
  if (opts.direction === "forward") {
    fator = -1;
    console.log("forward");

    return animation
      .create()
      .addElement(opts.enteringEl)
      .beforeStyles({ opacity: 1 })
      .easing("ease-in")
      .duration(300)
      .fromTo("transform", `translateX(${fator * -100}%)`, "translateX(0%)");
  } else if (opts.direction === "back") {
    const rootAnimation = animation
      .create()
      .addElement(opts.enteringEl)
      .easing("ease-in")
      .beforeStyles({ opacity: 1 })
      .duration(300)
      .fromTo("transform", `translateX(${fator * -100}%)`, "translateX(0%)");

    const leaveAnimation = animation
      .create()
      .addElement(opts.leavingEl)
      .easing("ease-out")
      .duration(300)
      .fromTo("transform", "translateX(0%)", `translateX(${fator * 100}%)`);

    return animation.create().addAnimation([rootAnimation, leaveAnimation]);
  }
};
