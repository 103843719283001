import ItemPedido from './item-pedido';
import ConfiguracaoPedido from './configuracao-pedido.interface';

export default interface ItemCart {
    idProduto: number;
    codigoProduto: number;
    nomeProduto: string;
    valor: number;
    novoValor: number;
    listaConfiguracoes: ConfiguracaoPedido[];
    quantidade: number;
    idTamanho?: number;
    nomeTamanho?: string;
    obsItem?: string;
    urlImg?: string;

    toItemPedido(): ItemPedido;
}

export class ItemCartModel implements ItemCart {
    idProduto: number;
    codigoProduto: number;
    nomeProduto: string;
    valor: number;
    novoValor: number;
    listaConfiguracoes: ConfiguracaoPedido[];
    quantidade: number;
    idTamanho?: number;
    nomeTamanho?: string;
    obsItem?: string;
    urlImg?: string;

    constructor(
        idProduto: number,
        codigoProduto: number,
        nomeProduto: string,
        valor: number,
        listaConfiguracoes: ConfiguracaoPedido[],
        quantidade: number,
        idTamanho: number = null,
        NomeTamanho: string = null,
        obsItem: string = '') {

        this.idProduto = idProduto;
        this.codigoProduto = codigoProduto;
        this.nomeProduto = nomeProduto;
        this.valor = valor;
        this.listaConfiguracoes = listaConfiguracoes;
        this.quantidade = quantidade;
        this.idTamanho = idTamanho;
        this.nomeTamanho = NomeTamanho;
        this.obsItem = obsItem;

    }

    toItemPedido(): ItemPedido {
        return {
            idProduto: this.idProduto,
            codigoProduto: this.codigoProduto,
            quantidade: this.quantidade,
            idTamanho: this.idTamanho,
            nomeTamanho: this.nomeTamanho,
            obsItem: this.obsItem,
            listaConfiguracoes: this.listaConfiguracoes.map(
                values => ({
                    idConfiguraProduto: values.idConfiguraProduto,
                    listaVariaveis: values.listaVariaveis.map(v => v.idProduto)
                })
            )
        };
    }
}
