import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { LadTotemService } from '../services/ladtotem.service';
import Categoria from '../models/categoria.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriasResolveGuard implements Resolve<Categoria[]> {

  constructor(
    private ladTotemService: LadTotemService
  ) { }

  /*resolve = (): Observable<Categoria[]> => new Observable(observer => {

    this.ladTotemService.consultaCategoriasTotem().then(
      categorias => {
        observer.next(categorias);
        observer.complete();
      },
      err => {
        console.error(err);
        this.ladTotemService.presentAlertRequestUrl(
          () => this.ladTotemService.consultaCategoriasTotem()
        ).then(
          categorias
        );
      }
    );
  })*/

  async resolve(): Promise<Categoria[]> {
    try {
      return await this.ladTotemService.consultaCategoriasTotem();
    } catch (err) {
      console.error(err);

     /* return new Promise<Categoria[]>(
        (resolve, reject) => this.ladTotemService.presentAlertRequestUrl(
          () => resolve(this.resolve())
        )
      ); */
    }
  }
}
