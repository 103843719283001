import { EmpresaOfflineComponent } from './pages/empresa-offline/empresa-offline.component';
import { MatButtonModule } from '@angular/material/button';
import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";
import { environment } from "../environments/environment";
import { myTransitionRouter } from "./animations/transitionRouter";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ErroModule } from "./components/erro/erro.module";
import { PesquisaSatisfacaoComponent } from "./components/pesquisa-satisfacao/pesquisa-satisfacao.component";
import { PesquisaSatisfacaoModule } from "./components/pesquisa-satisfacao/pesquisa-satisfacao.module";
import { TimerComponent } from "./components/timer/timer.component";
import { TimerModule } from "./components/timer/timer.module";
import { VisualizarContaComponent } from "./components/visualizar-conta/visualizar-conta.component";
import { BoasVindasModule } from "./pages/boas-vindas/boas-vindas.module";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found";
import { CachingInterceptor } from "./services/caching-interceptor.service";
import { RequestCache } from "./services/request-cache.service";
import { CardapioTabletPageModule } from "./pages/cardapio-tablet/cardapio-tablet.module";

registerLocaleData(localePt, "pt");

@NgModule({
  declarations: [
    AppComponent,
    VisualizarContaComponent,
    PageNotFoundComponent,
    EmpresaOfflineComponent
  ],
  entryComponents: [
    TimerComponent,
    VisualizarContaComponent,
    PesquisaSatisfacaoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
       navAnimation: myTransitionRouter
    }),
    IonicStorageModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatTableModule,
    TimerModule,
    ErroModule,
    PesquisaSatisfacaoModule,
    BoasVindasModule,
    CardapioTabletPageModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    MatButtonModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    RequestCache,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
