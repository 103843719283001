import { LadTotemService } from "src/app/services/ladtotem.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-empresa-offline",
  templateUrl: "./empresa-offline.component.html",
  styleUrls: ["./empresa-offline.component.scss"],
})
export class EmpresaOfflineComponent implements OnInit {
  constructor(
    private router: Router
  ) {}

  ngOnInit() {}

  public voltarHome(): void {
    this.router.navigate(["/boas-vindas"]);
  }
}
