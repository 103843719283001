import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfiguracaoService} from './configuracao.service';
import { take, timeout } from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Storage} from '@ionic/storage';
import { LadTotemService } from './ladtotem.service';

export enum Nota {
  N1 = 1, N2 = 2, N3 = 3, N4 = 4, N5 = 5
}

export interface Pergunta {
  token: string;
  pergunta: string;
  email: string;
  resposta?: Nota;
  respostaObs?: string;
  idPergunta: number;
}

@Injectable({
  providedIn: 'root'
})
export class PesquisaSatisfacaoService {

  constructor(private httpClient: HttpClient, private configService: ConfiguracaoService,
    private ladTotemService: LadTotemService, private storage: Storage) {}
  /** Constante de registro de dados no [Storage] */
  private static readonly registryPrefix = 'pesquisa$';

  /** Constante contendo o id de pergunta de Sugestões, Reclamações ou Perguntas. */
  public static readonly idSugestao = 101;

  /**  Caminho de recurso da API */
  private readonly path = '/ladfood/ws/ladTotemAvaliacao';

  /**
   * Função estática geradora de perguntas para uma pesquisa.
   * @param token O token da mesa registrado na aplicação
   * @returns um observável contendo uma lista de [Pergunta]s.
   */
  private static getFake(token: string) {
    return of<Partial<Pergunta>[]>([
      {
        token,
        pergunta: 'Como foi o nosso atendimento?',
        idPergunta: 0
      },
      {
        token,
        pergunta: 'O que achou da nossa comida?',
        idPergunta: 1
      },
      {
        token,
        pergunta: 'Como foi o tempo de espera?',
        idPergunta: 2
      },
      {
        token,
        pergunta: 'O que achou dos nossos preços?',
        idPergunta: 3
      },
    ]) as Observable<Pergunta[]>;
  }

  /**
   * Envia as resposas da pesquisa de satisfação, para serem salvas e apuradas.
   * @param token O token da mesa onde a pesquisa de satisfação será atrelada.
   * @param dados uma lista de perguntas, com suas devidas respostas.
   */
  async enviarPesquisaSatisfacao(token: string, dados: Pergunta[]) {
    const urlLocal = await this.ladTotemService.urlServidorLocal$.pipe(take(1)).toPromise();
    const url = urlLocal ? urlLocal : await this.configService.url$.pipe(take(1)).toPromise();
    return this.httpClient.post(`${url}${this.path}`, dados)
      .pipe(
        timeout(8000)
      )
      .toPromise();
  }

  /**
   * Procura as perguntas da pesquisa de satisfação registradas na base de dados.
   * @param token O token da mesa
   * @returns um observável contendo uma lista de perguntas.
   */
  async getPesquisa(token: string): Promise<Pergunta[]> {
    let pesquisa = await this.storage.get(`${PesquisaSatisfacaoService.registryPrefix}${token}`) as Pergunta[];
    if (!pesquisa) {
      pesquisa = await PesquisaSatisfacaoService.getFake(token).toPromise();
    }
    return pesquisa;
  }
}
