import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

import { TimerService } from 'src/app/services/timer.service';
import { CpfUtils } from 'src/app/utils/cpf';

@Component({
  selector: 'app-teclado-numerico',
  templateUrl: './teclado-numerico.component.html',
  styleUrls: ['./teclado-numerico.component.scss']
})
export class TecladoNumericoComponent implements OnInit {
  @Input() cpf: string;

  public readonly labelsBtn = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 12];
  public readonly BTN_LIMPAR = 10;
  public readonly BTN_BACKSPACE = 12;
  public isNew: boolean;
  public isValid = true;

  constructor(
    private timerService: TimerService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.isNew = this.cpf === '';
  }

  onClick() {
    this.timerService.restart();
  }

  onBtn(btn) {
    if (btn === this.BTN_LIMPAR) {
      this.cpf = '';
      this.isValid = true;
      return;
    }

    if (btn === this.BTN_BACKSPACE) {
      this.cpf = this.cpf.substr(0, this.cpf.length - 1);
      return;
    }

    if (this.cpf.length === 11) {
      return;
    }

    this.cpf += btn;
    if (this.cpf.length === 11) {
      this.updateIsValid();
    }
  }

  onClose() {
    this.modalController.dismiss();
  }

  onAdd() {
    this.updateIsValid();
    if (this.isValid || this.cpf.length === 0) {
      this.modalController.dismiss(this.cpf);
    }
  }

  updateIsValid() {
    this.isValid = CpfUtils.isValidCpf(this.cpf);
  }

}
