import { Component, OnInit } from '@angular/core';

import { ModalController, LoadingController, AlertController } from '@ionic/angular';

import { LadTotemService } from 'src/app/services/ladtotem.service';
import Comanda from '../../models/comanda.interface';
import { TokenMesaService } from '../../services/token-mesa.service';

@Component({
  selector: 'app-visualizar-conta',
  templateUrl: './visualizar-conta.component.html',
  styleUrls: ['./visualizar-conta.component.scss']
})
export class VisualizarContaComponent implements OnInit {

  displayedColumns = ['produto', 'qtd', 'valor', 'total'];
  dadosComanda: Comanda;
  valorTotal = 0;
  taxaServico = 0;
  subTotal = 0;


  constructor(
    public modalController: ModalController,
    public loadingController: LoadingController,
    private service: LadTotemService,
    private tokenMesaService: TokenMesaService,
    private alertController: AlertController
  ) { }

  async ngOnInit() {
    const tokenMesa = await this.getToken();
    await this.consultaItensPedidos(tokenMesa);
  }

  private async consultaItensPedidos(tokenMesa: number) {

    if (tokenMesa) {
      this.presentLoading();
      this.service.consultaComanda(tokenMesa).then(
        response => {
        if (response.sucesso) {
          this.dadosComanda = response.dados;
          this.calculaValor();
        } else {
          this.tokenMesaService.removeTokenMesa();
          this.presentAlert(
            `${response.msg} 😐`,
            'Você será redirecionado para página inicial, para efetuar um novo pedido! 😀',
            () => this.close()
          );
        }

        this.loadingController.dismiss();
        },
        err => {
          console.warn(err);
          this.loadingController.dismiss();
          this.presentAlert(
            'Ah, não! 😭',
            'Algo deu errado ao tentar buscar seu pedido... Tente novamente mais tarde.',
            () => this.close()
          );
        }
      );
    }
  }

  private getToken() {
    return this.tokenMesaService.getTokenMesa();
  }

  private calculaValor() {
    this.valorTotal = this.dadosComanda.valorTotal;
    this.subTotal = this.dadosComanda.subTotal;
    this.taxaServico = this.dadosComanda.taxaServico;
  }

  public getFormattedPrice(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Carregando',
    });
    await loading.present();
  }

  private async presentAlert(header: string, message: string, callback: () => void) {
    const alert = await this.alertController.create({
      header,
      message,
      cssClass: 'alert-500',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: callback
        }
      ]
    });

    await alert.present();
  }

  async close() {
    await this.modalController.dismiss();
  }
}
