import { Banner } from "./../models/banner";
import { Estabelecimento } from "./../models/estabelecimento";
import { TokenMesaService } from "./token-mesa.service";
import ConsumirPedidoInterface from "../models/consumir-pedido.model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { first, retry, take, timeout } from "rxjs/operators";
import { AlertController } from "@ionic/angular";

import { ConfiguracaoService } from "./configuracao.service";
import Categoria from "../models/categoria.interface";
import ConsumirPedidoMesa from "../models/consumir-pedido-mesa";
import RespostaServidor from "../models/resposta-servidor.interface";
import Produto from "../models/produto.interface";
import ConfiguracaoProduto from "../models/configuracao-produto.interface";
import VariavelProduto from "../models/variavel-produto.interface";
import ItemPedido from "../models/item-pedido";
import Comanda from "../models/comanda.interface";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

/** URL base para carregamento de imagens de categorias de alimentação. */
const IMG_BASE_URL = "https://img.ladfood.com.br/categoria/";

/** URL base para carregamento de imagens de produtos de alimentação. */
const IMG_PRODUCT_BASE_URL = "https://img.ladfood.com.br/produto/";
// const IMG_PRODUCT_BASE_URL = 'https://s3.amazonaws.com/ladtotem.ladfood.com.br/';

/** Endpoint da API para consultar categorias, produtos e configurações. */
const CONSULTA_ENDPOINT = "ladfood/ws/consultarCategoriasTotemV2";
// const CONSULTA_ENDPOINT = 'ladfood/ws/consultarCategoriasTotem';

/** Endpoint da API para consultar dados das empresas. */
const CONSULTA_EMPRESA_ENDPOINT = "ladfood/ws/ladTotemConsultarEmpresa";

/** Endpoint da API para calcular preços de items. */
const CALCULA_PRECO_ENDPOINT = "ladfood/ws/ladTotemCalcularPreco";

const CONSUMIR_PEDIDO_MESA_ENDPOINT = "ladfood/ws/ladTotemConsumirPedidoMesa";

const CONSUMIR_PEDIDO_ENDPOINT = "ladfood/ws/ladTotemConsumirPedido";

const CONSULTAR_COMANDA_ENDPOINT = "ladfood/ws/ladTotemConsultarComanda";

export interface RetornoConsultaCategoria {
  listaCategorias: Categoria[];
  listaProdutos: Produto[];
  listaConfiguracoes: ConfiguracaoProduto[];
  listaVariaveis: VariavelProduto[];
}

@Injectable({
  providedIn: "root",
})
export class LadTotemService {
  /**
   * Cache de categorias de alimentação.
   *
   * Ao iniciar a aplicação, este serviço também é inicializado, pelo método `consultaCategoriasTotem()`,
   * e cria este cache para manter boa performance na aplicação.
   */
  private categoriasMap = new Map<string, Categoria>();

  private categoriasSubject = new BehaviorSubject<Categoria[]>([]);

  public get categorias$(): Observable<Categoria[]> {
    return this.categoriasSubject.asObservable();
  }

  public get categorias(): Map<string, Categoria> {
    return new Map(this.categoriasMap);
  }

  /**
   * Cache de produtos de alimentação.
   *
   * Ao iniciar a aplicação, este serviço também é inicializado, pelo método `consultaCategoriasTotem()`,
   * e cria este cache para manter boa performance na aplicação.
   */
  private produtosMap = new Map<string, Produto>();

  private produtosSubject = new BehaviorSubject<Produto[]>([]);

  public get produtos$(): Observable<Produto[]> {
    return this.produtosSubject.asObservable();
  }

  public get produtos(): Map<string, Produto> {
    return new Map(this.produtosMap);
  }

  /**
   * Cache de configurações para os produtos de alimentação.
   *
   * Ao iniciar a aplicação, este serviço também é inicializado, pelo método `consultaCategoriasTotem()`,
   * e cria este cache para manter boa performance na aplicação.
   */
  private configuracoesMap = new Map<string, ConfiguracaoProduto>();

  private configuracoesSubject = new BehaviorSubject<ConfiguracaoProduto[]>([]);

  private urlServidorLocalSubject = new BehaviorSubject<string | null>(null);

  public get urlServidorLocal$(): Observable<string | null> {
    return this.urlServidorLocalSubject.asObservable();
  }

  public get configuracoes$(): Observable<ConfiguracaoProduto[]> {
    return this.configuracoesSubject.asObservable();
  }

  public get configuracoes(): Map<string, ConfiguracaoProduto> {
    return new Map(this.configuracoesMap);
  }

  /**
   * Cache de variáveis configuráveis para os produtos de alimentação.
   *
   * Ao iniciar a aplicação, este serviço também é inicializado, pelo método `consultaCategoriasTotem()`,
   * e cria este cache para manter boa performance na aplicação.
   */
  private variaveisMap = new Map<string, VariavelProduto>();

  private variaveisSubject = new BehaviorSubject<VariavelProduto[]>([]);

  public get variaveis$(): Observable<VariavelProduto[]> {
    return this.variaveisSubject.asObservable();
  }

  public get variaveis(): Map<string, VariavelProduto> {
    return new Map(this.variaveisMap);
  }

  private setCategorias(value: Categoria[]) {
    value.forEach((categoria) =>
      this.categoriasMap.set(categoria.idCategoria.toString(), categoria)
    );
    this.categoriasSubject.next(value);
  }

  private setProdutos(value: Produto[]) {
    value.forEach((produto) => {
      produto.img = this.getImagemDeUmProduto(produto.idProduto);
      this.produtosMap.set(produto.idProduto.toString(), produto);
    });
    this.produtosSubject.next(value);
  }

  private setConfiguracoes(value: ConfiguracaoProduto[]) {
    value.forEach((configuracao) =>
      this.configuracoesMap.set(
        `${configuracao.idConfiguraProduto}`,
        configuracao
      )
    );
  }

  private setVariaveis(value: VariavelProduto[]) {
    value.forEach((variavel) =>
      this.variaveisMap.set(`${variavel.idVariavelProduto}`, variavel)
    );
  }

  constructor(
    private http: HttpClient,
    private configService: ConfiguracaoService,
    private tokenMesaService: TokenMesaService,
    private alertController: AlertController,
    private router: Router
  ) {}

  public async consultarBanner(): Promise<Banner[]> {
    const idEmpresa = (await this.consultaEmpresa()).id;
    const idBanners = await this.http
      .post<Banner[]>(
        environment.ladwebServer +
          "/ladfood/ws/consultarBannersCardapioDigital",
        { idEmpresa }
      )
      .toPromise();

    return idBanners;
  }

  /**
   * Inicializa o cache de categorias e produtos.
   */
  private async initCache(): Promise<void> {
    let url = await this.configService.url$.pipe(take(1)).toPromise();

    const isLadWeb = await this.configService.isLadWeb$
      .pipe(take(1))
      .toPromise();

    await this.consultaEmpresa();
    const urlServidorLocal = await this.urlServidorLocal$
      .pipe(take(1))
      .toPromise();

    let endpoint = CONSULTA_ENDPOINT;

    if (isLadWeb && !urlServidorLocal) {
      if (environment.ladwebServer) {
        url = environment.ladwebServer;
        const nickName = await this.configService.ladWebNickName$
          .pipe(take(1))
          .toPromise();
        endpoint = `${endpoint}/${nickName}`;
      }
    }

    const token = await this.tokenMesaService.getTokenMesa();
    const urlConsultaCategoria = urlServidorLocal ? urlServidorLocal : url;
    try {
      const resposta = await this.http
        .request("post", `${urlConsultaCategoria}/${endpoint}`, {
          body: {
            token: token,
          },
          observe: "response",
        })
        .pipe(retry(1), timeout(15000))
        .toPromise();

      if (resposta.status === 203) {
        console.warn(
          `Status ${resposta.status}: Categorias não serão atualizadas.`
        );
        return;
      }

      const {
        listaCategorias,
        listaProdutos,
        listaConfiguracoes,
        listaVariaveis,
      } = resposta.body as RetornoConsultaCategoria;

      this.setCategorias(listaCategorias);
      this.setProdutos(listaProdutos);
      this.setConfiguracoes(listaConfiguracoes);
      this.setVariaveis(listaVariaveis);
    } catch (err) {
      console.warn(err);
      if (urlServidorLocal) {
        await this.router.navigate(["empresa-offline"]);
      } else {
        await this.router.navigate(["pagina-nao-encontrada"]);
      }
    }
  }

  /** Recarrega o cache. */
  public async reloadCache(): Promise<void> {
    this.produtosMap = new Map<string, Produto>();
    this.categoriasMap = new Map<string, Categoria>();
    this.variaveisMap = new Map<string, VariavelProduto>();
    this.configuracoesMap = new Map<string, ConfiguracaoProduto>();
    await this.initCache();
  }

  /**
   * Consulta as categorias de alimentação registradas.
   * Esta função assíncrona processa os dados retornados pela API, e deixa salvo no cache interno deste
   * serviço.
   * @returns uma `Promise` podendo ou não conter um `Array` de [Categoria].
   */
  public async consultaCategoriasTotem(): Promise<Categoria[]> {
    if (this.categorias.size < 1) {
      await this.initCache();
    }

    return Array.from(this.categorias.values());
  }

  public async consultaProdutosTotem(): Promise<Produto[]> {
    if (this.produtos.size < 1) {
      await this.initCache();
    }

    return Array.from(this.produtos.values());
  }

  public async consultaEmpresa(): Promise<Estabelecimento> {
    const nickName: string | number = await this.configService.ladWebNickName$
      .pipe(take(1))
      .toPromise();
    const url: string = await this.configService.url$.pipe(take(1)).toPromise();

    const isLadWeb: boolean = await this.configService.isLadWeb$
      .pipe(take(1))
      .toPromise();

    if (isLadWeb) {
      const empresaLadWeb = await this.http
        .get<Estabelecimento>(
          `${environment.ladwebServer}/${CONSULTA_EMPRESA_ENDPOINT}/${nickName}`
        )
        .pipe(take(1))
        .toPromise();

      if (!!empresaLadWeb.urlEstabelecimento) {
        this.urlServidorLocalSubject.next(empresaLadWeb.urlEstabelecimento);
      }

      return empresaLadWeb;
    }

    const empresaServidorLocal = await this.http
      .get<Estabelecimento>(`${url}/${CONSULTA_EMPRESA_ENDPOINT}`)
      .pipe(take(1))
      .toPromise();

    if (!!empresaServidorLocal.urlEstabelecimento) {
      this.urlServidorLocalSubject.next(
        empresaServidorLocal.urlEstabelecimento
      );
    }
    return empresaServidorLocal;
  }

  /**
   * Busca uma categoria por ID no cache.
   * @param id o ID da categoria
   * @returns A [Categoria] no cache, ou `null` caso não exista esta categoria.
   */
  public getCategoria(id: number | string): Categoria | null {
    return this.categorias.get(`${id}`) || null;
  }

  /**
   * Busca um produto por ID no cache.
   * @param id o ID do produto
   * @returns o [Produto] no cache, ou `null` caso não exista esse produto.
   */
  public getProduto(id: number | string): Produto | null {
    return this.produtos.get(`${id}`) || null;
  }

  public getProdutosByCategoria(idCategoria: number | string): Produto[] {
    const toProdutoArray = Array.from(this.produtos.values());

    return toProdutoArray.filter(
      (produto) => `${produto.idCategoria}` === `${idCategoria}`
    );
  }

  /**
   * Busca uma configuração por ID no cache.
   * @param idConfiguracao o ID da configuração
   * @returns a [ConfiguracaoProduto] no cache, ou `null` caso não exista essa configuração.
   */
  public getConfiguracao(
    idConfiguracao: number | string
  ): ConfiguracaoProduto | null {
    return this.configuracoes.get(`${idConfiguracao}`) || null;
  }

  /**
   * Busca uma variável por ID no cache.
   * @param idVariavel o ID da variável
   * @returns a [VariavelProduto] no cache, ou `null` caso não exista essa variável.
   */
  public getVariavel(idVariavel: number | string): VariavelProduto | null {
    return this.variaveis.get(`${idVariavel}`) || null;
  }

  public getVariaveisByConfiguracao(
    idConfiguraProduto: number | string
  ): VariavelProduto[] {
    const toVariavelArray = Array.from(this.variaveis.values());

    return toVariavelArray.filter(
      (variavel) => `${variavel.idConfiguraProduto}` === `${idConfiguraProduto}`
    );
  }

  /**
   * Envia um pedido para calcular seu custo no servidor.
   * @param pedido o pedido a ser calculado
   * @returns a resposta do servidor, como `Promise`.
   */
  public async calculaPreco(pedido: ItemPedido): Promise<RespostaServidor> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const url = await this.configService.url$.pipe(take(1)).toPromise();

    const isLadWeb = await this.configService.isLadWeb$
      .pipe(take(1))
      .toPromise();
    const urlServidorLocal = await this.urlServidorLocal$
      .pipe(take(1))
      .toPromise();

    let endpoint = CALCULA_PRECO_ENDPOINT;

    if (isLadWeb && !urlServidorLocal) {
      const nickName = await this.configService.ladWebNickName$
        .pipe(take(1))
        .toPromise();
      endpoint = `${endpoint}/${nickName}`;
    }

    if (!!urlServidorLocal) {
      return this.http
        .post<RespostaServidor>(`${urlServidorLocal}/${endpoint}`, pedido, {
          headers,
        })
        .toPromise();
    }

    return this.http
      .post<RespostaServidor>(`${url}/${endpoint}`, pedido, { headers })
      .toPromise();
  }

  /**
   * Consome o pedido de um cliente.
   * @param body os dados de consumo para enviar à API
   * @returns a resposta do servidor, como `Promise`.
   */
  public async consumirPedido(
    body: ConsumirPedidoInterface
  ): Promise<RespostaServidor> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const url = await this.configService.url$.pipe(take(1)).toPromise();
    return this.http
      .post<RespostaServidor>(`${url}/${CONSUMIR_PEDIDO_ENDPOINT}`, body, {
        headers,
      })
      .pipe(timeout(20000))
      .toPromise();
  }

  /**
   * Consome o pedido de um cliente em mesa registrada.
   * @param body os dados de consumo para enviar à API
   * @returns a resposta do servidor, como `Promise`.
   */
  public async consumirPedidoMesa(
    body: ConsumirPedidoMesa
  ): Promise<RespostaServidor> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    const urlServidorLocal = await this.urlServidorLocal$
      .pipe(take(1))
      .toPromise();
    const url = urlServidorLocal
      ? urlServidorLocal
      : await this.configService.url$.pipe(take(1)).toPromise();

    return this.http
      .post<RespostaServidor>(`${url}/${CONSUMIR_PEDIDO_MESA_ENDPOINT}`, body, {
        headers,
      })
      .pipe(timeout(20000))
      .toPromise();
  }

  /**
   * Consulta uma comanda registrada.
   * @param tokenMesa o token da mesa onde está registrada a comanda.
   * @returns a [Comanda] em `Promise`, caso exista, ou null.
   */
  public async consultaComanda(
    tokenMesa: number
  ): Promise<RespostaServidor<Comanda>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    const urlLocal = await this.urlServidorLocal$.pipe(take(1)).toPromise();
    const url = urlLocal
      ? urlLocal
      : await this.configService.url$.pipe(take(1)).toPromise();
    return this.http
      .post<RespostaServidor<Comanda>>(
        `${url}/${CONSULTAR_COMANDA_ENDPOINT}`,
        { tokenMesa },
        { headers }
      )
      .pipe(timeout(20000))
      .toPromise();
  }

  /**
   * Monta a URL de imagem de uma categoria.
   * @param idCategoria o ID da categoria.
   * @returns URL da imagem de uma categoria.
   */
  public getImagemDeUmCategoria(idCategoria: number): string {
    return `${IMG_BASE_URL}${idCategoria}.jpg`;
  }

  /**
   * Monta a URL de imagem de um produto.
   * @param idProduto o ID do produto.
   * @returns URL da imagem de um produto.
   */
  public getImagemDeUmProduto(idProduto: number): string {
    return `${IMG_PRODUCT_BASE_URL}${idProduto}p.jpg`;
  }

  /**
   * Cria um popup de alerta na janela, para requisitar a URL do servidor
   * @param callback uma função a ser executada quando o popup é dispensado
   */
  public async presentAlertRequestUrl(callback: () => void) {
    let savedUrl = await this.configService.url$.pipe(take(1)).toPromise();
    if (savedUrl) {
      savedUrl = savedUrl.replace(/([htps]+:\/\/)+/, "");
    }
    const alert = await this.alertController.create({
      header: "Insira a url do servidor",
      inputs: [
        {
          name: "url",
          type: "url",
          placeholder: "Url do servidor",
          value: savedUrl,
        },
      ],
      backdropDismiss: false,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Ok",
          handler: (form) => {
            const url = (form.url as string).replace(/([htps]+:\/\/)+/, "");
            return this.configService.setUrl(`http://${url}`);
          },
        },
      ],
    });

    await alert.present();

    if (callback) {
      alert.onDidDismiss().then(callback);
    }
  }
}
