import { first } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';

import { AlertController, LoadingController, MenuController, NavController } from '@ionic/angular';
import * as pluralize from 'pluralizador';
import * as extenso from 'extenso';

import { LadTotemService } from 'src/app/services/ladtotem.service';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import ItemCart, { ItemCartModel } from 'src/app/models/item-cart.model';
import { fadeAndMoveAnimation, valueChangeAnimation } from 'src/app/animations/animations';
import { ToastService } from 'src/app/services/toast.service';
import Produto from '../../../models/produto.interface';
import ConfiguracaoProduto from '../../../models/configuracao-produto.interface';
import VariavelProduto from '../../../models/variavel-produto.interface';
import { map, take, timeout } from 'rxjs/operators';
import TamanhoProduto from '../../../models/tamanho-produto.interface';
import { maxSelectedCheckboxes, minSelectedCheckboxes } from '../../../utils/validators';
import { MatListOption } from '@angular/material/list';
import { Observable, of } from 'rxjs';
import ConfiguracaoPedido from '../../../models/configuracao-pedido.interface';
import VariavelPedido from '../../../models/variavel-pedido.interface';
import { BreakpointService } from '../../../services/breakpoint.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfiguracaoService } from '../../../services/configuracao.service';

/** Id para formulário de seleção de tamanhos */
const ID_FORM_TAMANHO = "tamanhoForm";

@Component({
  selector: "app-produto-detalhe",
  templateUrl: "./produto-detalhe.component.html",
  styleUrls: ["./produto-detalhe.component.scss"],
  animations: [valueChangeAnimation, fadeAndMoveAnimation],
})
export class ProdutoDetalheComponent implements OnInit, OnDestroy {
  variaveis: Array<VariavelProduto> = [];
  novoValor: number;
  isUpdatingValor = false;

  messages = new Map<number, string>();
  isMessageDanger = new Map<number, boolean>();

  itemCart: ItemCart;
  produto: Produto;
  quantidade: number;

  listaConfiguracoes: ConfiguracaoProduto[];
  variaveisMap = new Map<number, VariavelProduto[]>();
  tamanho: TamanhoProduto;

  formulario = new FormGroup({});

  public isLadWeb$ = this.configService.isLadWeb$;
  public habilitarSelecaoOpcionais = true;
  public allowLocalServerOrderRequest$: Observable<string | null> =
    this.service.urlServidorLocal$;

  constructor(
    private service: LadTotemService,
    public breakpointService: BreakpointService,
    private route: ActivatedRoute,
    private nav: NavController,
    private menu: MenuController,
    private serviceToast: ToastService,
    private loadingController: LoadingController,
    private snackbarController: MatSnackBar,
    private carrinho: CarrinhoService,
    private configService: ConfiguracaoService,
    private serviceLadTotem: LadTotemService
    ) {
      this.route.params.subscribe(() => {
        this.init();
      });
  }

  async ngOnInit() {
    await this.init();
  }
  
  private async init() {
    const loading = await this.loadingController.create({
      message: "CARREGANDO PRODUTO...",
    });
    await loading.present();

    const isLadWeb = await this.configService.isLadWeb$
      .pipe(take(1))
      .toPromise();
    const possuiServidorLocalParametizado = await this.service.urlServidorLocal$
      .pipe(
        take(1),
        map((v) => !!v)
      )
      .toPromise();

    if (isLadWeb && !possuiServidorLocalParametizado) {
      this.habilitarSelecaoOpcionais = false;
    }
    
    await this.serviceLadTotem.consultaProdutosTotem()
   
    const idProduto = await this.recuperarIdProduto();

    if (!idProduto) {
      await loading.dismiss();
      return;
    }

    this.produto = this.service.getProduto(idProduto);

    // Inicializa tamanho com o primeiro disponível
    this.tamanho = this.produto.listaTamanhos[0];

    this.initItemCart();

    const configuracoes =
      this.produto.listaIdConfiguracoes.map<ConfiguracaoProduto>((configId) =>
        this.service.getConfiguracao(configId)
      );

    configuracoes.forEach((config) => {
      const variaveis = this.service.getVariaveisByConfiguracao(
        config.idConfiguraProduto
      );

      // Cache de variáveis em um hashmap, para fácil localização através do ID de configuração.
      this.variaveisMap.set(config.idConfiguraProduto, variaveis);

      /**
       * No caso de invalidez do formulário, uma configuração terá o `isMessageDanger.get(idConfiguraProduto)`
       * como true, e exibirá um destaque em vermelho, para chamar a atenção do usuário.
       */
      this.isMessageDanger.set(config.idConfiguraProduto, config.qtdMinima > 0);

      // Descrição de configuração, i.e.: "Item Obrigatório" ou "Selecione no máximo X itens"
      this.messages.set(config.idConfiguraProduto, config.descricao);
    });

    // Organiza a lista de configurações na sequência provida pela ordem.
    this.listaConfiguracoes = configuracoes.sort((a, b) => a.ordem - b.ordem);
    this.buildForm();
    this.buildMessageForm();

    this.listaConfiguracoes.forEach((i) => {
      const variaveis = this.variaveisMap.get(i.idConfiguraProduto);
      variaveis.forEach((variavel) => {
        this.variaveis.push(variavel);
      });
    });

    await loading.dismiss();
  }

  /**
   * Referência: Bug #30448
   *
   * @returns O idProduto especificado na rota atual.
   */
  private async recuperarIdProduto() {
    try {
      const routeParams = await this.route.params
      .pipe(take(1), timeout(200))
      .toPromise();
      
      return routeParams.idProduto;
    } catch (error) {
      return null;
    }
  }

  /** Inicializa o formulário para configuração de um produto */
  private buildForm() {
    // Controle de formulário para seleção de tamanho de item, apenas se disponível.
    if (this.existeMaisDeUmTamanho()) {
      const formArrayTam = new FormArray(
        [],
        [minSelectedCheckboxes(1), maxSelectedCheckboxes(1)]
      );
      this.produto.listaTamanhos.forEach(() => {
        formArrayTam.push(new FormControl(false));
      });
      this.formulario.addControl(ID_FORM_TAMANHO, formArrayTam);
    }

    // Criação de formulários através das configurações existentes para um produto
    this.listaConfiguracoes.map((config) => {
      const formArray = new FormArray(
        [],
        [
          minSelectedCheckboxes(config.qtdMinima),
          maxSelectedCheckboxes(config.qtd),
        ]
      );

      const variaveis = this.variaveisMap.get(config.idConfiguraProduto);

      variaveis.forEach(() => {
        formArray.push(new FormControl(false));
      });

      this.formulario.addControl(
        config.idConfiguraProduto.toString(),
        formArray
      );
    });
  }

  /** Inicializa o item que, ao final da configuração do produto, irá para o carrinho. */
  private initItemCart() {
    this.itemCart = new ItemCartModel(
      this.produto.idProduto,
      this.produto.codigoProduto,
      this.produto.nomeProduto,
      this.produto.valor,
      [],
      1
    );
    this.quantidade = 1;

    // Pré seleciona o primeiro tamanho do item no carrinho.
    this.tamanho = this.produto.listaTamanhos[0];
    this.itemCart.nomeTamanho = this.tamanho.nomeTamanho;
    this.itemCart.idTamanho = this.tamanho.idTamanho;
  }

  /** Seleciona um tamanho dentre os existentes. */
  async selectTamanho(
    options: QueryList<MatListOption>,
    selectedOption: TamanhoProduto
  ): Promise<void> {
    // Para evitar que o usuário fique cliacando e fique aparecendo mensagem de erro
    if (!this.habilitarSelecaoOpcionais) {
    } else {
      const configForm = this.formulario.get(ID_FORM_TAMANHO) as FormArray;

      const optArray = options.toArray();

      const selectionValues = optArray.map<boolean>(
        (opt) =>
          (opt.value as TamanhoProduto).idTamanho === selectedOption.idTamanho
      );

      selectionValues.forEach((value, index) => {
        configForm.at(index).setValue(value);
        options.toArray()[index].selected = value;
      });

      const tamIndex = (configForm.value as boolean[]).findIndex(
        (value) => value
      );

      this.tamanho = this.produto.listaTamanhos[tamIndex];
      this.itemCart.nomeTamanho = this.tamanho.nomeTamanho;
      this.itemCart.idTamanho = this.tamanho.idTamanho;

      const valorAtualizado = await this.updateValor();

      if (!valorAtualizado) {
        optArray.forEach((option, index) => {
          option.selected = false;
          configForm.at(index).setValue(false);
        });

        // Um hack, para evitar recriar snackbars quando clica em múltiplos itens, e evitar criar se o componente não
        // está pronto / está destruído.
        if (!this.snackbarController._openedSnackBarRef && this.formulario) {
          this.snackbarController.open(
            "Não foi possível atualizar seu pedido... Tente novamente mais tarde.",
            undefined,
            {
              duration: 3000,
              verticalPosition: "top",
              panelClass: ["snackbar-error"],
            }
          );
        }
      }
    }
  }

  /** Marca uma variável de configuração do alimento */
  async selectVariavelConfig(
    idConfiguraProduto: number,
    options: QueryList<MatListOption>,
    selectedOption: MatListOption
  ): Promise<void> {
    const configForm = this.formulario.get(
      idConfiguraProduto.toString()
    ) as FormArray;

    const optArray = options.toArray();

    const selectionValues = optArray.map<boolean>((opt) => opt.selected);

    selectionValues.forEach((value, index) =>
      configForm.at(index).setValue(value)
    );

    const indexOfSelected = optArray.findIndex(
      (opt) =>
        (opt.value as VariavelProduto).idVariavelProduto ===
        (selectedOption.value as VariavelProduto).idVariavelProduto
    );

    if (configForm.invalid) {
      const { qtdMax } = this.getQtdsRequiredConfig(idConfiguraProduto);

      const vals = configForm.value as boolean[];

      const newVals: boolean[] = [];

      if (qtdMax === 1) {
        vals.forEach((value, index) => newVals.push(index === indexOfSelected));
      } /*else {
        vals.forEach( (value, index) => newVals.push( index === indexOfSelected ? false : value ) );
      }*/

      newVals.forEach((value, index) => {
        optArray[index].selected = value;
        configForm.at(index).setValue(value);
      });

      // Se ainda continuar inválido...
      if (configForm.invalid) {
        const errorMessage = this.getErrorMessageForm(
          configForm,
          idConfiguraProduto
        );
        this.serviceToast
          .presentToast(errorMessage)
          .then(() => this.isMessageDanger.set(idConfiguraProduto, true));
      }
    } else {
      this.isMessageDanger.set(idConfiguraProduto, false);
    }

    this.updateConfigItemCart(idConfiguraProduto);

    const valorAtualizado = await this.updateValor();

    if (!valorAtualizado) {
      configForm.at(indexOfSelected).setValue(false);
      selectedOption.selected = false;

      // Um hack, para evitar recriar snackbars quando clica em múltiplos itens, e evitar criar se o componente não
      // está pronto / está destruído.
      if (!this.snackbarController._openedSnackBarRef && this.formulario) {
        this.snackbarController.open(
          "Não foi possível atualizar seu pedido... Tente novamente mais tarde.",
          undefined,
          {
            duration: 3000,
            verticalPosition: "top",
            panelClass: ["snackbar-error"],
          }
        );
      }

      this.updateConfigItemCart(idConfiguraProduto);
    }
  }

  invalidForm(): Observable<boolean> {
    return of<boolean>(this.formulario.invalid);
  }

  goBack() {
    this.nav.setDirection("back");
    this.nav.back();
  }

  /** Disparado apenas quando o formulário é válido */
  async sendToCart() {
    const loading = await this.loadingController.create({
      message: "ENVIANDO...",
    });
    await loading.present();

    await this.addItemShopCart();
  }

  /** Adiciona quantidade através de multiplicador */
  onPlusQuantidade() {
    if (this.quantidade >= 20) {
      return;
    }

    this.quantidade += this.produto.qtdMultipla;
  }

  /** Subtrai quantidade através de multiplicador. Se a quantidade for 1, não faz nada. */
  onSubQuantidade() {
    if (this.quantidade === 1) {
      return;
    }
    this.quantidade -= this.produto.qtdMultipla;
  }

  /** Construção do formulário para validação dos dados. */
  private buildMessageForm() {
    this.listaConfiguracoes.map((config) => {
      let message = null;

      if (config.qtdMinima === 1) {
        message = "Item obrigatório";
      } else if (config.qtdMinima === 0 && config.qtd > 1) {
        message = `Escolha até ${config.qtd} opções`;
      } else if (config.qtdMinima > 1 && config.qtd === 0) {
        message = `Escolha pelo menos ${config.qtdMinima} opções`;
      }

      this.messages.set(config.idConfiguraProduto, message);
    });
  }

  async openMenuCategoria() {
    await this.menu.open("first");
  }

  /**
   * Atualiza as configurações do [ItemCart]. Se uma configuração não foi registrada na lista de configurações,
   * cria o item na lista, e adiciona as variáveis configuradas. Se uma configuração já existe, atualiza suas
   * variáveis, substituindo-as por variáveis atualizadas.
   * @param idConfiguraProduto O id de configuração do produto para atualizar.
   */
  private updateConfigItemCart(idConfiguraProduto: number): void {
    const configForm = this.formulario.get(
      idConfiguraProduto.toString()
    ) as FormArray;
    const variaveis = this.variaveisMap.get(idConfiguraProduto);

    const listaVariaveis: VariavelPedido[] = [];

    // Para cada variável do formulário, verifica quais estão selecionadas e adiciona à lista de variáveis
    (configForm.value as boolean[]).forEach((value, index) => {
      if (value) {
        const { idProduto, nomeVariavel } = variaveis[index];
        listaVariaveis.push({ idProduto, nomeVariavel });
      }
    });

    const configEscolhidas: ConfiguracaoPedido = {
      idConfiguraProduto,
      listaVariaveis,
    };

    // Encontra o index de uma configuração na lista de configurações, para atualizar.
    const configIndex = this.itemCart.listaConfiguracoes.findIndex(
      (value) => value.idConfiguraProduto === idConfiguraProduto
    );

    // Caso o index não exista (ou seja, -1), cria uma nova entrada na lista de configurações do item.
    // Senão, apenas atualiza a configuração.
    if (configIndex === -1) {
      this.itemCart.listaConfiguracoes.push(configEscolhidas);
    } else {
      this.itemCart.listaConfiguracoes[configIndex] = configEscolhidas;
    }
  }

  /**
   * Atualiza o valor do item, para exibição.
   *
   * @returns um `boolean`. Se for `true`, significa que o valor foi atualizado com sucesso. Se for `false`,
   * significa que houve um erro duranto a atualização do preço, e a ação deve ser desfeita.
   */
  private async updateValor(): Promise<boolean> {
    const itemPedido = this.itemCart.toItemPedido();
    // Descarta todas as configurações que não possuem variáveis selecionadas.
    itemPedido.listaConfiguracoes = itemPedido.listaConfiguracoes.filter(
      (v) => v.listaVariaveis.length !== 0
    );

    try {
      this.isUpdatingValor = true;
      const response = await this.service.calculaPreco(itemPedido);

      if (response.sucesso) {
        if (response.valor === this.produto.valor) {
          this.itemCart.novoValor = null;
        } else {
          this.itemCart.novoValor = response.valor;
        }
      }
      this.isUpdatingValor = false;
      return true;
    } catch (err) {
      return false;
    }
  }

  existeMaisDeUmTamanho() {
    return this.produto.listaTamanhos.length > 1;
  }

  private getErrorMessageForm(
    form: AbstractControl,
    idConfiguraProduto: number
  ): String {
    const errors = form.errors;
    const qtdsRequired = this.getQtdsRequiredConfig(idConfiguraProduto);

    if (errors.qtdMin !== undefined && errors.qtdMin) {
      return `Selecione no mínimo ${qtdsRequired.qtdMin} ${pluralize(
        "opção",
        qtdsRequired.qtdMin
      )} ! 😅`;
    }

    if (errors.qtdMax !== undefined && errors.qtdMax) {
      return `Selecione no máximo ${qtdsRequired.qtdMax} ${pluralize(
        "opção",
        qtdsRequired.qtdMax
      )} ! 😅`;
    }
  }

  /**
   * Identifica os requisitos de quantidade de uma configuração, retornando o máximo e o mínimo de itens.
   * @param idConfiguraProduto o ID de configuração a se identificar.
   * @returns um objeto com os campos `qtdMin` e `qtdMax`, para o mínimo e máximo de itens que podem ser selecionados,
   * respectivamente.
   */
  private getQtdsRequiredConfig(idConfiguraProduto: number): {
    qtdMin: number;
    qtdMax: number;
  } {
    const config = this.service.getConfiguracao(idConfiguraProduto);

    return { qtdMin: config.qtdMinima, qtdMax: config.qtd };
  }

  /** Adiciona o item ao carrinho. */
  private async addItemShopCart() {
    this.nav.back();
    this.animateShopCart();

    // Descarta todas as configurações que não possuem variáveis selecionadas.
    this.itemCart.listaConfiguracoes = this.itemCart.listaConfiguracoes.filter(
      (v) => v.listaVariaveis.length !== 0
    );
    this.itemCart.quantidade = this.quantidade;
    this.itemCart.valor *= this.quantidade;
    this.itemCart.urlImg = this.produto.img;

    this.carrinho.addCarrinho(this.itemCart);
    // Transforma o nome do produto para palavras com apenas a primeira letra capitalizada.
    const nomeProduto = new TitleCasePipe().transform(this.produto.nomeProduto);

    await this.loadingController.dismiss();

    this.snackbarController.open(
      `'${nomeProduto}' adicionado ao carrinho! 😀`,
      undefined,
      {
        duration: 3000,
        panelClass: ["snackbar-success"],
        verticalPosition: "bottom",
      }
    );
  }

  private animateShopCart() {
    setTimeout(() => this.menu.open("end"), 500);
  }

  public extenso(num: number) {
    if (num !== undefined) {
      return extenso(num);
    }
  }

  ngOnDestroy() {
    this.formulario = undefined;
  }
}
