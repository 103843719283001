import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';

import { AlertController, LoadingController, MenuController, ModalController } from '@ionic/angular';
import * as pluralize from 'pluralizador';

import { CarrinhoService } from 'src/app/services/carrinho.service';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { LadTotemService } from 'src/app/services/ladtotem.service';
import ItemCart from 'src/app/models/item-cart.model';
import ConsumirPedidoInterface, { ConsumirPedidoModel } from 'src/app/models/consumir-pedido.model';
import { TecladoNumericoComponent } from 'src/app/components/teclado-numerico/teclado-numerico.component';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from '../../../environments/environment';
import ConsumirPedidoMesa, { ConsumirPedidoMesaModel } from '../../models/consumir-pedido-mesa';
import { VisualizarContaComponent } from 'src/app/components/visualizar-conta/visualizar-conta.component';
import { TokenMesaService } from '../../services/token-mesa.service';
import { map, take } from 'rxjs/operators';
import { PesquisaSatisfacaoComponent } from '../../components/pesquisa-satisfacao/pesquisa-satisfacao.component';
import CodigosResposta from '../../models/codigos-resposta';

@Component({
  selector: 'app-cardapio-tablet',
  templateUrl: './cardapio-tablet.page.html',
  styleUrls: [ './cardapio-tablet.page.scss' ],
})
export class CardapioTabletPage implements OnInit, OnDestroy {
  public readonly idMenuCarrinho = 'carrinho';

  public activatedCart = false;
  public itensCart = [];
  public valorTotal = 0;

  public cpf = '';
  private isViagem = false;
  private inscricoes: Subscription[] = [];
  private inscricaoTimer: Subscription;

  public open: boolean;
  public hasToken;
  public token = '';

  private consumirPedidoMesa: ConsumirPedidoMesa;

  public isLadWeb$ = this.serviceConfig.isLadWeb$;
  public allowLocalServerOrderRequest$: Observable<string | null> = this.serviceLadTotem.urlServidorLocal$;

  constructor(
    private menu: MenuController,
    private serviceCarrinho: CarrinhoService,
    private serviceConfig: ConfiguracaoService,
    private serviceLadTotem: LadTotemService,
    public alertController: AlertController,
    public modalController: ModalController,
    public loadingController: LoadingController,
    private serviceToast: ToastService,
    private router: Router,
    private tokenMesaService: TokenMesaService
  ) {
  }

  async ngOnInit() {
    this.menu.open('first');

    this.inscricoes.push(this.serviceCarrinho.getCarrinho().subscribe(values => this.itensCart = values));
    this.inscricoes.push(this.serviceCarrinho.getTotalValorCarrinho().subscribe(total => this.valorTotal = total));
    this.inscricoes.push(this.initSubscribeTokenMesa());

    // Ao carregar essa página, redirecionar para o componente de listagem de produtos
    const categorias = await this.serviceLadTotem.consultaCategoriasTotem();
    await this.router.navigate([ 'cardapio', categorias[0].idCategoria ]);

  }

  async onCancelarPedido() {
    const alert = await this.alertController.create({
      header: 'Deseja cancelar seu pedido? 😰',
      message: '<img style="margin-top: 10px" src="assets/img/ladFood.png" alt="">',
      cssClass: 'alert-500',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        },
        {
          text: 'Sim',
          handler: () => {
            this.goHome();
          }
        }
      ]
    });

    await alert.present();
  }

  async onReadCpf() {
    const modal = await this.modalController.create({
      component: TecladoNumericoComponent,
      cssClass: 'modal-teclado',
      componentProps: {
        'cpf': this.cpf
      }
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if ( data !== undefined ) {
      this.cpf = data;
    }
  }

  async onFinalizaPedido() {
    // Proteção para não efetuar duas requisições para consumirPedido
    const loading = await this.loadingController.getTop();
    if ( loading !== undefined ) {
      return;
    }

    const urlServidorLocal = await this.serviceLadTotem.urlServidorLocal$.pipe(take(1)).toPromise();

    if ( this.isMobile || urlServidorLocal) {
      if ( this.isToken ) {
        const token = await this.tokenMesaService.getTokenMesa();
        if (token) {
          this.finalizaPedidoMesa(token);
        } else {
          this.presentAlertToken(
            () => this.finalizaPedidoMesa(token)
          );
        }

      } else {
        await this.presentAlertMesa();
      }
    } else {
      await this.finalizaPedido();
    }
  }

  public async saiDaMesa() {
    const modal = await this.alertController.create({
      message: 'Deseja realmente sair da sua mesa?',
      cssClass: 'alert-500',
      buttons: [
        {
          text: 'Não',
          role: 'cancel'
        },
        {
          text: 'Sim',
          handler: async () => await this.confirmSaiDaMesa()
        }
      ]
    });

    await modal.present();

  }

  public async entrarMesa() {
    await this.presentAlertToken(async () => {
      const loading = await this.loadingController.create({message: 'Atualizando Cardápio...'});
      await loading.present();
      try {
        await this.serviceLadTotem.reloadCache();
      } finally {
        await loading.dismiss();
      }
    });
  }

  private async confirmSaiDaMesa() {
    this.tokenMesaService.removeTokenMesa();
    this.closeEnd();
    const loading = await this.loadingController.create({message: 'Atualizando Cardápio...'});
    await loading.present();
    try {
      await this.serviceLadTotem.reloadCache();
    } finally {
      await loading.dismiss();
    }
  }

  public async visualizaConta() {
    const modal = await this.modalController.create({
      component: VisualizarContaComponent
    });

    await modal.present();
  }

  public async abrirPesquisa() {
    const modal = await this.modalController.create({
      component: PesquisaSatisfacaoComponent
    });

    await modal.present();
  }

  onChangeViagem(value) {
    this.isViagem = value;
  }

  isCarEmpty() {
    return this.serviceCarrinho.isEmpty();
  }

  shouldShowSatisfactionResearch() {
    return this.serviceCarrinho.isEmpty().pipe(map(cartEmpty => cartEmpty && this.hasToken));
  }

  async toggleEnd() {
    // Não entra no if se o token estiver habilitado
    if ( this.itensCart.length === 0 && !environment.isToken ) {
      return this.serviceToast.presentToast('Ainda não tem nada no carrinho... 😐', 'dark');
    }

    await this.menu.toggle(this.idMenuCarrinho);
  }

  async closeEnd() {
    await this.menu.close(this.idMenuCarrinho);
  }

  private async presentAlert(header: string, redireciona: boolean = true, callback: () => void = () => {}) {
    let subscriptionTimer: Subscription = null;
    const alert = await this.alertController.create({
      header: header,
      cssClass: 'alert-500',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            if ( redireciona ) {
              reset(this);
            } else {
              callback();
            }
          }
        }
      ]
    });

    await alert.present();

    if ( redireciona ) {
      subscriptionTimer = timer(0, 1000).subscribe(seg => {
        this.alertController.getTop().then((ionAlert: HTMLIonAlertElement) => {
          ionAlert.message = `Você será rederecionado em ${ 5 - seg } ${ pluralize('segundo', 6 - seg) }`;
          ionAlert.message += '<br><br><img src="assets/img/ladFood.png" alt="">';
        });

        if ( seg === 6 ) {
          reset(this);
        }
      });
    }

    function reset(that) {
      that.alertController.dismiss();
      that.goHome();
      subscriptionTimer.unsubscribe();
    }
  }

  async presentLoading() {
    let msg;
    if ( this.isMobile ) {
      msg = 'Enviando Pedido!';
    } else {
      msg = 'Aguardando o pagamento';
    }
    const loading = await this.loadingController.create({
      message: msg,
    });
    await loading.present();
  }

  async dismissLoading() {
    await this.loadingController.getTop().then((loading: HTMLIonLoadingElement) => {
      loading.remove();
    });
  }

  private goHome() {
    this.open = false;

    this.serviceCarrinho.resetCarrinho();
    if ( this.inscricaoTimer && !this.inscricaoTimer.closed ) {
      this.inscricaoTimer.unsubscribe();
    }

    setTimeout(async () => {
      this.closeEnd();
      const categorias = this.serviceLadTotem.consultaCategoriasTotem();
      await this.router.navigate([ 'cardapio', categorias[0].idCategoria ]);
    }, 200);
  }

  private closePedido() {
    this.open = false;

    if ( this.inscricaoTimer ) {
      if ( !this.inscricaoTimer.closed ) {
        this.inscricaoTimer.unsubscribe();
      }
    }

    setTimeout(
      async () => {
        this.closeEnd();
        const cat = this.serviceLadTotem.consultaCategoriasTotem();
        await this.router.navigate([ 'cardapio', cat[0].idCategoria ]);
      },
      200
    );
  }

  private initSubscribeTokenMesa() {
    return this.tokenMesaService.hasToken.subscribe(hasToken => {
      this.hasToken = hasToken;
      this.tokenMesaService.getTokenMesa().then((token: string) => this.token = (token) ? token.toUpperCase() : '');
    });
  }

  async OnCloseCarrinho() {
    await this.menu.close(this.idMenuCarrinho);
  }

  getQtdCarrinho() {
    if ( this.itensCart.length === 0 ) {
      return 0;
    }
    return this.itensCart.map(item => item.quantidade).reduce((total, current) => total + current);
  }

  getLabelBtnFinaliza() {
    return (this.isMobile) ? 'Enviar Pedido' : 'Pagar';
  }

  get isMobile() {
    return environment.disabledPagemento;
  }

  get isToken() {
    return environment.isToken;
  }

  private async finalizaPedidoMesa(comanda: number | string) {
    if ( !this.consumirPedidoMesa ) {
      const idEmpresa = await this.serviceConfig.getEmpresaId().toPromise();
      this.consumirPedidoMesa = new ConsumirPedidoMesaModel(this.itensPedido, idEmpresa, 0);

      if ( this.isToken ) {
        this.consumirPedidoMesa.tokenMesa = await this.tokenMesaService.getTokenMesa();
      } else {
        if ( comanda && typeof comanda === 'number' && comanda > 0 ) {
          this.consumirPedidoMesa.comanda = comanda;
        } else {
          await this.serviceToast.presentToast('Informe uma mesa válida... 😐', 'danger');
          this.consumirPedidoMesa = null;
          return;
        }
      }
    }

    this.presentLoading();
    this.serviceLadTotem.consumirPedidoMesa(this.consumirPedidoMesa).then(
      res => {
        if ( res.cod && res.cod === CodigosResposta.TOKEN_INVALIDO ) {
          this.presentAlert(res.msg, false, () =>
            this.presentAlertToken(
              () => this.finalizaPedidoMesa(comanda)
            )
          );
        } else if (res.cod && res.cod === CodigosResposta.RECARREGAR_CACHE) {
          this.presentAlert(res.msg, false, async () => {
            console.log('reloading...');
            await this.serviceLadTotem.reloadCache();
            });
        } else {
          this.presentAlert(res.msg, res.sucesso);
        }
        this.dismissLoading();
        this.consumirPedidoMesa = null;
      },
      async err => {
        console.warn(err);
        this.dismissLoading();
        const alert = await this.alertController.create({
          header: 'Ah, não! 😭',
          message: 'Algo deu errado ao tentar finalizar seu pedido... Tente novamente mais tarde.',
          cssClass: 'alert-500',
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
              handler: () => {
                this.alertController.dismiss();
                this.closePedido();
              }
            }
          ]
        });
        await alert.present();
      }
    );
  }

  private async finalizaPedido() {
    const idEmpresa = await this.serviceConfig.getEmpresaId().toPromise();

    const cp: ConsumirPedidoInterface = new ConsumirPedidoModel(this.itensPedido, idEmpresa, 1);

    this.presentLoading();
    this.serviceLadTotem.consumirPedido(cp).then(
      res => {
        if ( res.sucesso ) {
          this.presentAlert(res.msg);
        } else {
          this.presentAlert(res.msg, false);
        }
        this.dismissLoading();
      },
      async err => {
        console.warn(err);
        this.dismissLoading();
        const alert = await this.alertController.create({
          header: 'Ah, não! 😭',
          message: 'Algo deu errado ao tentar finalizar seu pedido... Tente novamente mais tarde.',
          cssClass: 'alert-500',
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
              handler: () => {
                this.alertController.dismiss();
                this.closePedido();
              }
            }
          ]
        });
        await alert.present();
      }
    );
  }

  async presentAlertMesa() {
    const alert = await this.alertController.create({
      header: 'Informe sua mesa?',
      inputs: [
        {
          name: 'comanda',
          type: 'number',
          placeholder: 'Mesa',
          min: 1
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Informar',
          handler: (form) => {
            const comanda = Number.parseInt(form.comanda, 10);
            this.finalizaPedidoMesa(comanda);
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertToken(callback: () => void = () => {
  }) {
    const alert = await this.alertController.create({
      header: 'Informe seu token?',
      inputs: [
        {
          name: 'token',
          type: 'text',
          placeholder: 'Token',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Informar',
          handler: (form) => {
            const token = form.token;
            this.tokenMesaService.salvaTokenMesa(token);
            callback();
          }
        }
      ]
    });

    await alert.present();
  }

  get itensPedido() {
    return this.itensCart.map((item: ItemCart) => item.toItemPedido());
  }

  ngOnDestroy() {
    this.inscricoes.forEach(inscricao => {
      if ( !inscricao.closed ) {
        inscricao.unsubscribe();
      }
    });
  }
}
