import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { LadTotemService } from '../../services/ladtotem.service';
import Categoria from '../../models/categoria.interface';
import { MenuController } from '@ionic/angular';
import { BreakpointService } from '../../services/breakpoint.service';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss'],
})
export class CategoriaComponent implements OnInit, OnDestroy {

  public categorias: Categoria[];
  private inscricao: Subscription;

  constructor(
    private service: LadTotemService,
    private menu: MenuController,
    public breakpointService: BreakpointService,
    private router: Router) { }

  ngOnInit() {
    this.inscricao = this.service.categorias$.subscribe(categorias => this.categorias = categorias);
  }

  getUrlImagem(idCategoria: number) {
    return this.service.getImagemDeUmCategoria(idCategoria);
  }

  async closeMenu() {
    const isMobile = this.breakpointService.isMobile$.toPromise();
    if (isMobile) {
      await this.menu.close('first');
    }
  }

  async navegarParaCategoria(idCategoria: number) {
    await this.router.navigate(['cardapio', idCategoria]);
    await this.closeMenu();
  }

  ngOnDestroy() {
    if (!this.inscricao.closed) {
      this.inscricao.unsubscribe();
    }
  }
}
