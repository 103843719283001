import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {

  @Input() waitTime: number;
  public segundos;
  private subs: Subscription;

  constructor(private modal: ModalController) { }

  ngOnInit() {
    if (this.waitTime === undefined) {
      this.waitTime = 10;
    }

    this.subs = timer(0, 1000).subscribe((segundos: number) => {
      this.segundos = this.waitTime - segundos;

      if (this.segundos === 0) {
        this.subs.unsubscribe();
      }
    });
  }

  onCloseModal(opt = null) {
    this.modal.dismiss(opt);
  }

  ngOnDestroy() {
    if (!this.subs.closed) {
      this.subs.unsubscribe();
    }
  }

}
