import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * Serviço de observação de Breakpoints no CSS.
 */
@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  /** Separação em instanciação privada e getter publico para evitar possibilidade de sobrescrição. */
  private isMobile = this.breakpointObserver
    .observe(
      [
        '(max-width: 767px)'
      ]
    )
    .pipe(
      map( result => result.matches ),
      shareReplay()
    );

  /**
   * Observável de breakpoints. Observa se o estado atual da janela equivale a uma tela menor, ou seja,
   * tanto um Tablet quanto um celular em modo retrato, ou celulares com a tela extremamente pequena.
   */
  public get isMobile$(): Observable<boolean> {
    return this.isMobile;
  }

  constructor( private breakpointObserver: BreakpointObserver ) { }
}
