import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';

import { LadTotemService } from 'src/app/services/ladtotem.service';
import Produto from 'src/app/models/produto.interface';
import { Subscription } from 'rxjs';
import { BreakpointService } from '../../../services/breakpoint.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-produto-list',
  templateUrl: './produto-list.component.html',
  styleUrls: [ './produto-list.component.scss' ],
})
export class ProdutoListComponent implements OnInit, OnDestroy {

  produtos: Produto[];
  titulo = 'Categorias';

  private idCategoria: number;

  private subscription: Subscription;

  constructor(
    private ladTotemService: LadTotemService,
    public breakpointService: BreakpointService,
    private route: ActivatedRoute,
    private navController: NavController,
    private menu: MenuController
  ) {
  }

  async ngOnInit() {
    const routeParams = await this.route.params.pipe(take(1)).toPromise();

    const { idCategoria } = routeParams;

    this.idCategoria = idCategoria;
    const categoria = this.ladTotemService.getCategoria(idCategoria);
    this.titulo = categoria.nomeCategoria || '';

    this.subscription = this.ladTotemService.produtos$.subscribe(async () => {
      this.produtos = this.ladTotemService.getProdutosByCategoria(idCategoria);
      if (this.produtos.length < 1) {
        const categorias = await this.ladTotemService.consultaCategoriasTotem();
        await this.navController.navigateForward(['cardapio', categorias[0].idCategoria]);
      }
    });

  }

  async openProdutoDetalhe(idProduto: number) {
    await this.navController.navigateForward([ 'cardapio', this.idCategoria, idProduto ]);
  }

  async openMenuCategoria() {
    await this.menu.open('first');
  }

  ngOnDestroy(): void {
    if ( this.subscription && !this.subscription.closed ) {
      this.subscription.unsubscribe();
    }
  }

  async refresh(dismiss: () => void): Promise<void> {
    try {
      await this.ladTotemService.reloadCache();
    } finally {
      dismiss();
    }
  }

}
