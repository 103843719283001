import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { TimerComponent } from './timer.component';

@NgModule({
  declarations: [TimerComponent],
  imports: [
    CommonModule,
    IonicModule,
    NgCircleProgressModule.forRoot()
  ],
  exports: [TimerComponent]
})
export class TimerModule { }
