import { trigger, style, animate, transition, query, group } from '@angular/animations';

export const listAnimation = trigger('listAnimation', [
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1, height: '*' }),
    animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
     style({
       transform: 'scale(0.5)', opacity: 0,
       height: '0px', margin: '0px'
     }))
  ]),
  transition(':enter', [
    style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
    animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
      style({ transform: 'scale(1)', opacity: 1 }))  // final
  ])
]);

export const valueChangeAnimation = trigger('valueChangeAnimation', [
  transition(':increment', group([
    query('.change-value', [
      style({ color: 'blue' }),
      animate('0.5s ease-out', style('*'))
    ])
  ])),
  transition(':decrement', group([
    query('.change-value', [
      style({ color: 'red' }),
      animate('0.5s ease-out', style('*'))
    ])
  ]))
]);

export const fadeAndMoveAnimation = trigger('fadeAndMoveAnimation', [
  transition(':leave', [
    style({ opacity: 1, height: '*', transform: 'translateY(0)' }),
    animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1)',
    style({ opacity: 0, height: '0px', transform: 'translateY(-60px)' }))
  ]),
  transition(':enter', [
    style({ opacity: 0, height: '0px', transform: 'translateY(-60px)'}),
    animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1)',
      style({ opacity: 1, height: '*', transform: 'translateY(0)' })),
  ]),
]);

