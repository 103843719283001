import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProdutoListComponent } from './produto-list/produto-list.component';
import { ProdutoDetalheComponent } from './produto-detalhe/produto-detalhe.component';
import { AngularMaterialModule } from '../../angular-material.module';
import { ImagemListProdutoModule } from '../imagem-list-produto/imagem-list-produto.module';
import { PullToRefreshModule } from '../pull-to-refresh/pull-to-refresh.module';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    ProdutoListComponent,
    ProdutoDetalheComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FormsModule,
    ImagemListProdutoModule,
    PullToRefreshModule,
    MatIconModule,
  ],
  exports: [
    ProdutoListComponent,
    ProdutoDetalheComponent
  ]
})
export class ProdutoModule { }
