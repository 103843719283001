import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoasVindasComponent } from './boas-vindas.component';
import { MatButtonModule } from '@angular/material/button';
import { BoasVindasRoutingModule } from './boas-vindas-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    BoasVindasRoutingModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    IonicModule
  ],
  declarations: [BoasVindasComponent]
})
export class BoasVindasModule { }
