import { Component, OnInit } from '@angular/core';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-erro-servidor-nao-encontrado',
  templateUrl: './erro-servidor-nao-encontrado.component.html',
  styleUrls: ['./erro-servidor-nao-encontrado.component.scss']
})
export class ErroServidorNaoEncontradoComponent implements OnInit {

  public url;

  constructor(private configService: ConfiguracaoService) { }

  ngOnInit() {
    this.configService.url$.pipe(take(1)).toPromise().then(url => this.url = url);
  }

}
