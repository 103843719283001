import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import { BehaviorSubject, concat, defer, fromEvent, merge, Observable, of, timer } from 'rxjs';
import {
  delay, filter,
  map,
  startWith,
  switchMap, take,
  takeUntil,
  takeWhile,
  tap
} from 'rxjs/operators';

export function tweenObservable(start: number, end: number, time: number): Observable<number> {
  const emissions = time / 10;
  const step = (start - end) / emissions;

  return timer(0, 10).pipe(
    map(x => start - step * (x + 1)),
    take(emissions)
  );
}

@Component({
  selector: 'app-pull-to-refresh',
  templateUrl: './pull-to-refresh.component.html',
  styleUrls: ['./pull-to-refresh.component.scss'],
  host: {
    class: 'refresh-body'
  },
  encapsulation: ViewEncapsulation.None
})
export class PullToRefreshComponent implements OnInit, AfterViewInit {
  @ViewChild('refreshContent', {read: ElementRef, static: true}) refreshContent!: ElementRef<HTMLDivElement>;

  @Input() color: ThemePalette;

  @Output() refresh = new EventEmitter<() => void>();

  touchStart$!: Observable<TouchEvent>;
  touchEnd$!: Observable<TouchEvent>;
  touchmove$!: Observable<TouchEvent>;

  scroll$ = new BehaviorSubject<number>(0);

  loading$ = new BehaviorSubject<boolean>(false);

  hasLoaded$ = this.loading$.pipe(
    map(loaded => !loaded),
    filter(hasLoaded => hasLoaded),
  );

  private currentPos = 0;

  completeAnimation$!: Observable<number>;

  drag$!: Observable<number>;

  position$!: Observable<number>;

  loadingPosition3d$!: Observable<string>;

  loadingRotateOnPosition$!: Observable<number>;

  loadingRotate$!: Observable<string>;

  loadingOpacity$!: Observable<number>;

  constructor() {
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.touchStart$ = fromEvent<TouchEvent>(this.refreshContent.nativeElement, 'touchstart');
    this.touchEnd$ = fromEvent<TouchEvent>(this.refreshContent.nativeElement, 'touchend');
    this.touchmove$ = fromEvent<TouchEvent>(this.refreshContent.nativeElement, 'touchmove');

    this.completeAnimation$ = this.hasLoaded$.pipe(
      map(
        () => this.currentPos
      ),
      switchMap(
        currentPos => tweenObservable(currentPos, 0, 75)
      )
    );

    this.drag$ = this.touchStart$.pipe(
      switchMap(
        start => {
          let pos = this.currentPos;
          return concat(
            this.scroll$.pipe(
              takeWhile(
                scroll => scroll > 0
              ),
              filter(
                scroll => scroll <= 0
              )
            ),
            this.loading$.pipe(
              switchMap(
                loading => loading
                  ? of(pos)
                  : concat(
                    this.touchmove$.pipe(
                      map(
                        move => move.touches[0].pageY - start.touches[0].pageY
                      ),
                      tap(value => pos = value),
                      takeUntil(this.touchEnd$),
                    ),
                    defer(
                      () => tweenObservable(pos, 0, 150)
                    )
                  )
              )
            )
              .pipe(
                takeWhile(value => value < (this.refreshContent.nativeElement.clientHeight / 5)),
              ),
            this.touchEnd$.pipe(
              switchMap(
                () => {
                  this.loading$.next(true);
                  this.refresh.emit(() => this.complete());
                  return tweenObservable(pos, 94, 50);
                }
              )
            )
          );
        }
      ),
    );

    this.position$ = merge(
      this.drag$,
      this.completeAnimation$
    ).pipe(
      startWith(0),
      delay(50),
      tap(
        value => this.currentPos = value
      )
    );

    this.loadingPosition3d$ = this.position$.pipe(
      map(position => `translate3d(0, ${position - 80}px, 0)`)
    );

    this.loadingRotateOnPosition$ = this.position$.pipe(
      map(
        value => 180 * (value / (this.refreshContent.nativeElement.clientWidth / 5))
      )
    );

    this.loadingRotate$ = this.loadingRotateOnPosition$.pipe(
      map(loadingRotation => `rotate(${loadingRotation}deg)`)
    );

    this.loadingOpacity$ = this.position$.pipe(
      map(
        value => (value / (this.refreshContent.nativeElement.clientHeight / 5)) / 2
      )
    );
  }

  complete(): void {
    this.loading$.next(false);
  }

}
