import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { Storage } from '@ionic/storage';

import { KeyStorage } from '../utils/key_storage';

@Injectable({
  providedIn: 'root'
})
export class TokenMesaService {

  private _hasToken: Subject<boolean> = new Subject();

  constructor(private storage: Storage) {
    this.initHasToken();
  }

  getTokenMesa() {
    return this.storage.get(KeyStorage.TOKEN_MESA);
  }

  salvaTokenMesa(token: string) {
    this.storage.set(KeyStorage.TOKEN_MESA, token)
      .then(() => this._hasToken.next(true));
  }

  removeTokenMesa() {
    this.storage.remove(KeyStorage.TOKEN_MESA)
      .then(() => this._hasToken.next(false));
  }

  private async initHasToken() {
    const token = await this.getTokenMesa();
    this._hasToken.next(token && true);
  }

  get hasToken(): Observable<boolean> {
    return this._hasToken.asObservable();
  }
}
