import * as dayjs from 'dayjs';

import ItemPedido from './item-pedido';

export default interface ConsumirPedidoMesa {
    data: string;
    listaItens: ItemPedido[];
    idEmpresa: number;
    idUsuario: number;
    comanda: number;
    tokenMesa: string;
}

export class ConsumirPedidoMesaModel implements ConsumirPedidoMesa {
    data: string;
    listaItens: ItemPedido[];
    idEmpresa: number;
    idUsuario: number;
    comanda: number;
    tokenMesa: string;

    constructor( listaItens: ItemPedido[], idEmpresa: number, idUsuario: number ) {
        this.data = dayjs().format('DD/MM/YYYY HH:mm:ss');
        this.listaItens = listaItens;
        this.idEmpresa = idEmpresa;
        this.idUsuario = idUsuario;
    }
}
