import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErroServidorNaoEncontradoComponent } from './components/erro-servidor-nao-encontrado/erro-servidor-nao-encontrado.component';

const routes: Routes = [
  { path: '', component: ErroServidorNaoEncontradoComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErroRoutingModule { }
