import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CardapioTabletRoutingModule } from './cardapio-tablet-routing.module';
import { HomePageModule } from '../home/home.module';
import { CardapioTabletPage } from './cardapio-tablet.page';
import { CarrinhoModule } from 'src/app/components/carrinho/carrinho.module';
import { ProdutoModule } from 'src/app/components/produto/produto.module';
import { CategoriaModule } from 'src/app/components/categoria/categoria.module';
import { TecladoNumericoModule } from 'src/app/components/teclado-numerico/teclado-numerico.module';
import { TecladoNumericoComponent } from 'src/app/components/teclado-numerico/teclado-numerico.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { AngularMaterialModule } from '../../angular-material.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CardapioTabletRoutingModule,
    CategoriaModule,
    ProdutoModule,
    CarrinhoModule,
    TecladoNumericoModule,
    PipesModule,
    HomePageModule,
    AngularMaterialModule
  ],
  declarations: [CardapioTabletPage],
  entryComponents: [
    TecladoNumericoComponent
  ]
})
export class CardapioTabletPageModule {}
