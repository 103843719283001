import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { ErroRoutingModule } from './erro-routing.module';
import { ErroServidorNaoEncontradoComponent } from './components/erro-servidor-nao-encontrado/erro-servidor-nao-encontrado.component';

@NgModule({
  declarations: [ErroServidorNaoEncontradoComponent],
  imports: [
    CommonModule,
    ErroRoutingModule,
    IonicModule
  ]
})
export class ErroModule { }
