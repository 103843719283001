import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { KeyStorage } from '../utils/key_storage';
import { SESSION_STORAGE } from '../utils/session-storage';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoService {

  private readonly url_file = 'config/config.json';

  private _urlSubject = new BehaviorSubject<string | null | undefined>(null);

  public get url$(): Observable<string | null | undefined> {
    return this._urlSubject.asObservable().pipe(
      switchMap(
        url => url != null
          ? of(url)
          : this.http.get(this.url_file).pipe(
          map((value: any) => value.url),
          take(1)
        )
      )
    );
  }

  private _ladWebNickName = new BehaviorSubject<string | null | undefined>(null);

  public get isLadWeb$(): Observable<boolean> {
    return this._ladWebNickName.asObservable().pipe(
      map(
        n => !!n
      )
    );
  }

  public get ladWebNickName$() {
    return this._ladWebNickName.asObservable();
  }

  constructor(
    private http: HttpClient,
    @Inject(SESSION_STORAGE) private storage: Storage
  ) { 
    this.init().then();
  }

  private async init(): Promise<void> {
    const url = await this.storage.getItem(KeyStorage.URL_SERVER);
    this._urlSubject.next(url || null);
    const ladWebNickName = await this.storage.getItem(KeyStorage.LAD_WEB_NICK_NAME);
    this._ladWebNickName.next(ladWebNickName);
  }

  getEmpresaId(): Observable<number> {
    return this.http.get(this.url_file).pipe(
      map((value: any) => value.idEmpresa),
      take(1)
    );
  }

  getServidor(servidor: string): Observable<string | null> {
    return this.http.get(this.url_file).pipe(
      map((value: any) => value.servers),
      map((servers: any) => servers[servidor] ? servers[servidor] : null),
      take(1)
    );
  }

  /**
   *
   * @param url que seja uma url válida
   * @param ladWebNickName id da empresa
   */
   async setUrl(
    url?: string | null,
    ladWebNickName?: string | null
  ): Promise<void> {
    if (url) {
      await this.storage.setItem(KeyStorage.URL_SERVER, url);
    }
    if (ladWebNickName) {
      await this.storage.setItem(KeyStorage.LAD_WEB_NICK_NAME, ladWebNickName);
    }
    if (url !== environment.ladwebServer) {
      environment.ladwebServer = url;
      await this.storage.removeItem(KeyStorage.LAD_WEB_NICK_NAME);
    }

    this._urlSubject.next(url);
    this._ladWebNickName.next(ladWebNickName);
  }

}
