import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {

  private carrinho: any[] = [];
  private carrinhoSubject = new Subject<any[]>();
  private total = 0;
  private totalSubject = new Subject<number>();

  constructor() {
    this.updateSubjects();
  }

  addCarrinho(item) {
    this.carrinho.push(item);
    this.calcularTotal();

    this.updateSubjects();
  }

  removeAtIndex(index) {
    this.carrinho = this.carrinho.slice(0, index).concat(this.carrinho.slice(index + 1));
    this.calcularTotal();

    this.updateSubjects();
  }

  getCarrinho(): Observable<any[]> {
    return this.carrinhoSubject.asObservable();
  }

  getTotalValorCarrinho(): Observable<number> {
    return this.totalSubject.asObservable();
  }

  isEmpty(): Observable<Boolean> {
    return of(this.carrinho.length === 0);
  }

  resetCarrinho() {
    this.carrinho = [];
    this.calcularTotal();

    this.updateSubjects();
  }

  private calcularTotal() {
    if (this.carrinho.length === 0) {
      this.total = 0;
      return;
    }

    this.total = this.carrinho
                  .map(i => (i.novoValor ? i.novoValor  * i.quantidade : i.valor))
                  .reduce((previous, current) => previous + current);
  }

  private updateSubjects() {
    this.updateCarrinhoSubject();
    this.updateTotalSubject();
  }

  private updateCarrinhoSubject() {
    this.carrinhoSubject.next(this.carrinho);
  }

  private updateTotalSubject() {
    this.totalSubject.next(this.total);
  }
}


