import { Injectable } from '@angular/core';
import { Subscription, timer, Subject } from 'rxjs';

import { AlertController, ModalController } from '@ionic/angular';

import { TimerComponent } from '../components/timer/timer.component';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  private timerEnd    = new Subject();
  public  timerEnd$   = this.timerEnd.asObservable();

  private timer: Subscription;
  private ionAlert: HTMLIonAlertElement;
  private readonly delayStart = 30; // Em segundos
  private readonly waitTime   = 10; // Em segundos

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
  ) { }

  public start() {
    this.timer = timer(this.delayStart * 1000, 1000).subscribe(segundos => {
      if (segundos === 0) {
        this.showTimer();
        this.timerEnd.next('start');
      }

      if (segundos === this.waitTime) {
        this.endTimer();
      }
    });
  }

  public stop() {
    this.removeTimer();

    this.timerEnd.next('stop');
  }

  public restart() {
    this.stop();
    this.start();
  }

  private endTimer() {
    this.timerEnd.next('end');

    this.removeTimer();
    if (this.ionAlert !== undefined) {
      this.ionAlert.remove();
    }

    // Remove modal e alerta abertos para voltar para o home
    this.alertController.getTop().then((alert: HTMLIonAlertElement) => (alert !== undefined) ? alert.remove() : null);
    this.modalController.getTop().then((modal: HTMLIonModalElement) => (modal !== undefined) ? modal.remove() : null);
  }

  async showTimer() {
    // Remove modal de CPF caso esteja aberto para mostrar o timer
    await this.modalController.getTop().then((m: HTMLIonModalElement) => (m !== undefined) ? m.remove() : null);

    const modal = await this.modalController.create({
      component: TimerComponent,
      cssClass: 'modal-timer',
      componentProps: {
        'waitTime': this.waitTime
      }
    });

    modal.onWillDismiss().then(() => {
      this.restart();
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data === 'reset') {
      this.endTimer();
    }
  }

  private removeTimer() {
    if (this.timer !== undefined && !this.timer.closed) {
      this.timer.unsubscribe();
    }
  }
}
