import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const MAX_AGE = 100000;

const CACHEABLE_URLS: string[] = [
  'ladfood/ws/consultarCategoriasTotem'
];

export interface CacheData<T = any> {
  url: string;
  response: HttpResponse<T>;
  ttl: number;
}

@Injectable()
export class RequestCache {

  private cache = new Map<string, CacheData>();

  /**
   * Gets an entry from the cache. This method takes for granted that the type `T` passed is the
   * one stored on the cache. It may lead to errors, pay caution to it.
   * @param req The request data, with the URL get the cached response.
   */
  get<T = any>(req: HttpRequest<T>): HttpResponse<T> | null {
    const url = req.url || '';
    const cached = this.cache.get(url);
    return cached ? cached.response as HttpResponse<T> : null;
  }

  put<T = any>(req: HttpRequest<T>, response: HttpResponse<T>): void {
    const url = req.url;

    // Caches the response of a connection only if the URL used is in part of the `CACHEABLE_URLS`
    CACHEABLE_URLS.forEach(
        cacheUrl => {
          if (url.endsWith(cacheUrl)) {
            const entry: CacheData = { url, response, ttl: Date.now() };
            this.cache.set(url, entry);
          }
        });

    // Runs expiration verification every time a response is cached.
    this.cleanExpiredEntries();
  }

  /**
   * Helper method to clean up entries in the cache that are possibly expired.
   */
  private cleanExpiredEntries(): void {
    const expired = Date.now() - MAX_AGE;

    this.cache.forEach(expiredEntry => {
      if (expiredEntry.ttl < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }
}
