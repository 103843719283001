import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { ProdutoListComponent } from 'src/app/components/produto/produto-list/produto-list.component';
import { ProdutoDetalheComponent } from 'src/app/components/produto/produto-detalhe/produto-detalhe.component';
import { CardapioTabletPage } from './cardapio-tablet.page';

const routes: Routes = [
  { path: '', component: CardapioTabletPage,
    children: [
      { path: ':idCategoria', component: ProdutoListComponent },
      { path: ':idCategoria/:idProduto', component: ProdutoDetalheComponent }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CardapioTabletRoutingModule { }
