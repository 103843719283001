import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  async presentToast(msg, color = 'dark') {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2500,
      position: 'middle',
      mode: 'ios',
      cssClass: `toast toast-${color}`,
    });
    toast.present();
  }
}
