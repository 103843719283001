import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-imagem-list-produto',
  templateUrl: './imagem-list-produto.component.html',
  styleUrls: ['./imagem-list-produto.component.scss'],
})
export class ImagemListProdutoComponent implements OnInit {
  /** A URL da imagem a ser apresentada. */
  @Input() imgUrl: string;
  /** O nome do produto. */
  @Input() nomeProduto: string;
  /** Valor do produto. */
  @Input() valorProduto: number;

  constructor() { }

  ngOnInit() {}

}
