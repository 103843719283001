/**
 * Function to create a validator for a minimum amount of selected checkboxes in a form.
 * @param min the minimum checkboxes that must be checked
 * @returns a `ValidatorFn` with the validator for the form validations.
 */
import { FormArray, ValidatorFn } from '@angular/forms';


export const minSelectedCheckboxes = (min: number = 0): ValidatorFn =>
  (formArray: FormArray) => {
    const totalChecked = formArray.controls
      .map(v => v.value)
      .reduce(
        (total, current) => current ? total + 1 : total,
        0
      );

    return totalChecked >= min ? null : { qtdMin: true };
  };

/**
 * Function to create a validator for a maximum amount of selected checkboxes in a form.
 * @param max the maximum checkboxes that must be checked
 * @returns a `ValidatorFn` with the validator for the form validations.
 */
export const maxSelectedCheckboxes = (max: number = 0): ValidatorFn => {
  return (formArray: FormArray) => {
    // Não possui limite
    if ( max === 0 ) {
      return;
    }

    const totalChecked = formArray.controls
      .map(v => v.value)
      .reduce((total, current) => current ? total + 1 : total, 0);

    return totalChecked <= max ? null : { qtdMax: true };
  };
};
